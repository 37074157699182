interface RadioProps {
    id: string
    value: string
    type: string
    title: string
    disabled: boolean
    callBack: (val: string) => void
}
interface RadioGroupProps {
    radioGroupList: RadioProps[]
}
const RadioGroup = ({ ...props }: RadioGroupProps) => {
    const radioGroupComponent = props.radioGroupList.map((radioGroup: RadioProps, index) => {
        let className = index === props.radioGroupList.length - 1 ? 'w-full rounded-t-lg' : 'w-full border-b border-gray-200 rounded-t-lg'
        return (
            <li key={radioGroup.id} className={className}>
                <div className="flex items-center ps-3">
                    <input
                        id={radioGroup.id}
                        type="radio"
                        value={radioGroup.value}
                        name={radioGroup.id}
                        checked={radioGroup.type === radioGroup.value}
                        onChange={(e) => radioGroup.callBack(e.target?.value)}
                        disabled={radioGroup.disabled}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500">
                    </input>
                    <label htmlFor={radioGroup.id} className="w-full py-3 ms-2 text-sm font-medium text-gray-900">
                        {radioGroup.title}
                    </label>
                </div>
            </li>
        )
    })
    return (
        <ul className="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">
            { radioGroupComponent }
        </ul>
    )
}
export default RadioGroup