import { useNavigate } from "react-router-dom"
import { useAuth } from "./AuthProvider"
import { ReactNode, useEffect, useState } from "react"

interface ProtectedRouteProps {
    children: ReactNode
}

const ProtectedRoute = ({ ...props }: ProtectedRouteProps) => {
    const navigate = useNavigate()

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const { session } = useAuth()

    useEffect(() => {
        if (!session?.userId || !session?.token) {
            setIsLoggedIn(false)
            return navigate('/login')
        }
        if (!session?.verified) {
            setIsLoggedIn(true)
            return navigate('/verifyAccount')
        }
        setIsLoggedIn(true)
    }, [isLoggedIn, navigate])



    return (
        <div>
            {isLoggedIn && props.children}
        </div>
    )
}

export default ProtectedRoute