import { useMutation, useQuery } from "@tanstack/react-query"
import { doSignUp, findAccount, getProfile, resetPassword } from "../api/api"
import Loading from "../components/Loading"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import Label from "../components/Label"
import MessagePane from "../components/MessagePane"
import Input from "../components/Input"
import ActionButton from "../components/ActionButton"
import MaskableInput from "../components/MaskableInput"
import { ProfileType } from "../types/types"

interface GenericServerResponse {
    type: string
    message: string
}
const PasswordResetPage = () => {
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams()
    let authenticated = searchParams.get("authenticated")
    let userId = null
    if (authenticated) {
        userId = localStorage.getItem("userId")
    }
    const getUserProfile = async () => {
        const userProfile: ProfileType = await getProfile()
        if (userProfile) {
            setEmail(userProfile.email)
            setAccountState("found")
            setPageState({
                type: "success",
                message: "Please proceed to resetting your password."
            })
        }
        return userProfile
    }
    const getProfileQuery = useQuery({
        queryKey: ['getProfileQuery'],
        queryFn: () => getUserProfile(),
        enabled: !!userId
    })
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [accountState, setAccountState] = useState("initial")
    const [pageState, setPageState] = useState<{ type: string, message: string }>({
        type: "",
        message: ""
    })
    const emailRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        emailRef.current?.focus()
    }, [])

    const processFindAccountSuccess = (mutationResponse: any) => {
        console.log('mutationResponse success', mutationResponse)
        const response: GenericServerResponse = mutationResponse
        setPageState({
            type: response.type,
            message: response.message
        })
        setAccountState("found")
        passwordRef.current?.focus()
    }
    const processFindAccountError = (mutationResponse: any) => {
        console.log('mutationResponse error', mutationResponse)
        const response: GenericServerResponse = mutationResponse
        setPageState({
            type: response.type,
            message: response.message
        })
        setAccountState("initial")
        emailRef.current?.focus()
    }
    const processResetPasswordSuccess = () => {
        localStorage.setItem('signUpState', "Password reset successful.")
        return navigate('/login')
    }

    const processResetPasswordError = (mutationResponse: any) => {
        console.log('mutationResponse error', mutationResponse)
        const response: GenericServerResponse = mutationResponse
        setPassword("")
        setPageState({
            type: response.type,
            message: response.message
        })
        emailRef.current?.focus()
    }

    const findAccountMutation = useMutation({
        mutationFn: async (email: string) => await findAccount(email),
        onSuccess: processFindAccountSuccess,
        onError: processFindAccountError
    })
    const resetPasswordMutation = useMutation({
        mutationFn: async (resetPasswordData: {
            email: string
            password: string
        }) => await resetPassword(resetPasswordData),
        onSuccess: processResetPasswordSuccess,
        onError: processResetPasswordError
    })

    const setEmailState = (value: string) => {
        setPageState({
            type: "",
            message: ""
        })
        setEmail(value)
    }

    const setPasswordState = (value: string) => {
        setPageState({
            type: "",
            message: ""
        })
        setPassword(value)
    }

    const findUserAccount = () => {
        if (!email) {
            setPageState({
                type: "error",
                message: "Please enter your email."
            })
            emailRef.current?.focus()
            return
        }
        findAccountMutation.mutate(email)
    }

    const resetUserPassword = async () => {
        if (!email) {
            setPageState({
                type: "error",
                message: "Please enter your email."
            })
            emailRef.current?.focus()
            return
        }
        if (!password) {
            setPageState({
                type: "error",
                message: "Please enter a password."
            })
            passwordRef.current?.focus()
            return
        }
        console.log('resetting password')
        resetPasswordMutation.mutate({
            email,
            password
        })

    }

    return (
        <div>
            {
                (findAccountMutation.isPending || resetPasswordMutation.isPending) && <Loading />
            }
            <div className="flex flex-col items-center">
                <div className="w-full sm:w-96 py-5 md:py-10 md:px-0 px-2">
                    <div className="pb-4 flex justify-center">
                        <Label className={"title-primary"}>Reset your password</Label>
                    </div>
                    {
                        (pageState.type === 'success' || pageState.type === 'error') &&
                        <MessagePane
                            type={pageState.type}
                            message={pageState.message}
                        />
                    }

                    {
                        (accountState === "initial" || accountState === "found") &&
                        <div className="pb-4">
                            <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Email</Label>
                            <Input id="login-email"
                                disabled={false}
                                value={email}
                                inputRef={emailRef}
                                className={"bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"}
                                callBack={setEmailState}
                            />
                        </div>
                    }
                    {
                        accountState === "found" &&
                        <div className="pb-4">
                            <Label className={"block pb-1 text-lg font-medium text-gray-900"}>New Password</Label>
                            <MaskableInput id={"login-password"}
                                disabled={false}
                                value={password}
                                inputRef={passwordRef}
                                type="password"
                                autoComplete="off"
                                className={"bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"}
                                callBack={setPasswordState}
                            />
                        </div>
                    }

                    {
                        accountState === "initial" &&
                        <div className="flex w-full justify-center">
                            <ActionButton
                                disabled={false}
                                className={"btn-primary"}
                                callBack={findUserAccount}>
                                Find Account
                            </ActionButton>
                        </div>
                    }
                    {
                        accountState === "found" &&
                        <div className="flex w-full justify-center">
                            <ActionButton
                                disabled={false}
                                className={"btn-primary"}
                                callBack={resetUserPassword}>
                                Reset Password
                            </ActionButton>
                        </div>
                    }
                    <div className="flex justify-center">
                        <a href="/signup">
                            <Label className={"text-m text-blue-600"}>
                                Don't have an account? Sign Up
                            </Label>
                        </a>
                    </div>
                    <div className="flex justify-center">
                        <a href="/login">
                            <Label className={"text-sm text-blue-600"}>
                                Already have an account? Log In
                            </Label>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PasswordResetPage