import { MutableRefObject } from "react"

interface InputProps {
    id: string
    value?: string
    inputRef?: React.RefObject<HTMLInputElement>
    disabled: boolean
    type?: string
    autoComplete?: string
    className: string
    callBack?: (val: any) => void
}

const Input = ({ ...props }: InputProps) => {
    return (
        <input
            type={props.type || "text"}
            id={props.id}
            value={props.value}
            className={props.className}
            ref={props.inputRef}
            disabled={props.disabled}
            autoComplete={props.autoComplete || "on"}
            onChange={(e) => props.callBack && props.callBack(e.target?.value)}
        />
    )
}
export default Input