interface DateInputProps {
    id: string
    value: string
    min: string
    max: string
    dateInputRef?: React.RefObject<HTMLInputElement>
    disabled: boolean
    className: string
    callBack: (val: string) => void
}
const DateInput = ({ ...props }: DateInputProps) => {
    return (
        <input type="date"
            id={props.id}
            name={props.id}
            value={props.value}
            min={props.min}
            max={props.max}
            ref={props.dateInputRef}
            className={props.className}
            disabled={props.disabled}
            onChange={(e) => props.callBack(e.target?.value)}
        />
    )
}
export default DateInput