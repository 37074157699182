import dotenv from 'dotenv'
import axios from "axios"
import { ProfileType, ReminderType } from "../types/types"

interface SignUpProps {
    name: string
    email: string
    password: string
}
interface LoginProps {
    email: string
    password: string
}

interface VerifyAccountProps {
    email: string
    passcode: string
}

let BASE_PATH = ''
if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    BASE_PATH = process.env.REACT_APP_BASE_PATH!
}

export const doSignUp = async (signUpData: SignUpProps): Promise<{
    type: string
    message: string
}> => {
    let response = null
    try {
        console.log('calling signup with data: ', signUpData)
        const serverResponse: {
            data: {
                isSuccess: boolean,
                type: string
            }
        } = await axios.post(`${BASE_PATH}/api/doSignUp`, {
            signUpData: signUpData
        })
        console.log('signup server response: ', serverResponse.data)
        if (serverResponse.data.isSuccess) {
            response = {
                type: "success",
                message: "Account successfully created."
            }
            return Promise.resolve(response)
        } else {
            if (serverResponse.data.type === "active") {
                response = {
                    type: "error",
                    message: "Already have an account with us? Consider Log In."
                }
            } else {
                response = {
                    type: "error",
                    message: "Server error. Please try again later."
                }
            }
        }
    }
    catch {
        response = {
            type: "error",
            message: "Server error. Please try again later."
        }
    }
    return Promise.reject(response)
}

export const doLogin = async (loginData: LoginProps): Promise<{
    type: string
    userId: string
    verified: boolean
    token: string
    message: string
}> => {
    let response = null
    try {
        console.log('calling login with data: ', loginData)
        const serverResponse: {
            data: {
                isSuccess: boolean,
                type: string
                userId: string
                token: string
            }
        } = await axios.post(`${BASE_PATH}/api/doLogin`, {
            loginData: loginData
        })
        console.log('login server response: ', serverResponse.data)
        if (serverResponse.data.isSuccess) {
            const verifiedStatus = serverResponse.data.type === "verified"
            if (verifiedStatus) {
                response = {
                    type: "success",
                    verified: true,
                    userId: serverResponse.data.userId,
                    token: serverResponse.data.token,
                    message: ""
                }
            } else {
                response = {
                    type: "success",
                    verified: false,
                    userId: serverResponse.data.userId,
                    token: serverResponse.data.token,
                    message: ""
                }
            }

            return Promise.resolve(response)
        } else {
            if (["non_active", "wrong_creds"].includes(serverResponse.data.type)) {
                response = {
                    type: "error",
                    verified: false,
                    userId: "",
                    token: "",
                    message: "Failed to Log In. Please check the email or password."
                }
            } else {
                response = {
                    type: "error",
                    verified: false,
                    userId: "",
                    token: "",
                    message: "Server error. Please try again later."
                }
            }
        }
    }
    catch {
        response = {
            type: "error",
            verified: false,
            userId: "",
            token: "",
            message: "Server error. Please try again later."
        }
    }
    return Promise.reject(response)
}

export const getProfile = async (): Promise<ProfileType> => {
    try {
        const userId = localStorage.getItem('userId')
        const userToken = localStorage.getItem('token')
        if (!userToken || !userId) {
            return Promise.reject({})
        }
        console.log('getting profile with userId: ', userId)
        const serverResponse: {
            data: {
                isSuccess: boolean,
                userProfile: ProfileType
            }
        } = await axios.post(`${BASE_PATH}/api/getProfile`, {
            token: userToken,
            userId: userId
        })
        console.log('get profile server response: ', serverResponse.data)
        if (serverResponse.data.isSuccess) {
            return Promise.resolve(serverResponse.data.userProfile)
        }
    }
    catch {
        console.log('error finding profile');
    }
    return Promise.reject({})
}

export const updateProfile = async (profileData: ProfileType): Promise<{
    type: string
    message: string
}> => {
    let response = null
    try {
        console.log('calling update profile with data: ', profileData)
        const userId = localStorage.getItem('userId')
        const userToken = localStorage.getItem('token')
        if (!userToken || !userId) {
            return Promise.reject([])
        }
        const serverResponse: {
            data: {
                isSuccess: boolean,
                type: string
            }
        } = await axios.post(`${BASE_PATH}/api/updateProfile`, {
            userId: userId,
            token: userToken,
            serverData: profileData
        })
        console.log('update profile server response: ', serverResponse.data)
        if (serverResponse.data.isSuccess) {
            response = {
                type: "success",
                message: "Profile updated successfully."
            }
            return Promise.resolve(response)
        } else {
            response = {
                type: "error",
                message: "Server error. Please try again later."
            }
        }
    }
    catch {
        response = {
            type: "error",
            message: "Server error. Please try again later."
        }
    }
    return Promise.reject(response)
}

export const findAccount = async (email: string): Promise<{
    type: string
    message: string
}> => {
    let response = null
    try {
        console.log('finding account with email: ', email)
        const serverResponse: {
            data: {
                isSuccess: boolean,
                type: string
            }
        } = await axios.post(`${BASE_PATH}/api/findAccount`, {
            email: email
        })
        console.log('findaccount server response: ', serverResponse.data)
        if (serverResponse.data.isSuccess) {
            response = {
                type: "success",
                message: "Account Found. Please proceed."
            }
            return Promise.resolve(response)
        } else {
            if (serverResponse.data.type === "non_active") {
                response = {
                    type: "error",
                    message: "No account found with this email. Check email or consider Sign Up."
                }
            } else {
                response = {
                    type: "error",
                    message: "Server error. Please try again later."
                }
            }
        }
    }
    catch {
        response = {
            type: "error",
            message: "Server error. Please try again later."
        }
    }
    return Promise.reject(response)
}

export const resetPassword = async (resetPasswordData: LoginProps): Promise<{
    type: string
    message: string
}> => {
    let response = null
    try {
        console.log('calling resetpassword with data: ', resetPasswordData)
        const serverResponse: {
            data: {
                isSuccess: boolean,
                type: string
            }
        } = await axios.post(`${BASE_PATH}/api/resetPassword`, {
            serverData: resetPasswordData
        })
        console.log('reset password server response: ', serverResponse.data)
        if (serverResponse.data.isSuccess) {
            response = {
                type: "success",
                message: "Password reset successfully. Please Log In."
            }
            return Promise.resolve(response)
        } else {
            response = {
                type: "error",
                message: "Server error. Please try again later."
            }
        }
    }
    catch {
        response = {
            type: "error",
            message: "Server error. Please try again later."
        }
    }
    return Promise.reject(response)
}


export const generatePasscode = async (email: string): Promise<{
    type: string
    message: string
}> => {
    let response = null
    try {
        console.log('generating passcode with email: ', email)
        const serverResponse: {
            data: {
                isSuccess: boolean,
                type: string
            }
        } = await axios.post(`${BASE_PATH}/api/generatePasscode`, {
            email: email
        })
        console.log('generate passcode server response: ', serverResponse.data)
        if (serverResponse.data.isSuccess) {
            response = {
                type: "success",
                message: "Passcode generated. Please check your email."
            }
            return Promise.resolve(response)
        } else {
            if (serverResponse.data.type === "non_active") {
                response = {
                    type: "error",
                    message: "Some error happened. Please verify the entered email."
                }
            } else {
                response = {
                    type: "error",
                    message: "Server error. Please try again."
                }
            }

        }

    }
    catch {
        response = {
            type: "error",
            message: "Server error. Please try again."
        }
    }
    return Promise.reject(response)
}

export const verifyAccount = async (verifyAccountData: VerifyAccountProps): Promise<{
    type: string
    message: string
}> => {
    let response = null
    try {
        console.log('verifying account with email: ', verifyAccountData.email)
        const serverResponse: {
            data: {
                isSuccess: boolean,
                type: string
            }
        } = await axios.post(`${BASE_PATH}/api/verifyAccount`, {
            serverData: verifyAccountData
        })
        console.log('verify account server response: ', serverResponse.data)
        if (serverResponse.data.isSuccess) {
            response = {
                type: "success",
                message: "Passcode verified. Redirecting to home."
            }
            return Promise.resolve(response)
        } else {
            if (serverResponse.data.type === "no_match") {
                response = {
                    type: "error",
                    message: "The code does not match. Please try again."
                }
            } else if (serverResponse.data.type === "expired") {
                response = {
                    type: "error",
                    message: "The code expired. Please resend."
                }
            } else {
                response = {
                    type: "error",
                    message: "Server error. Please try again."
                }
            }

        }
    }
    catch {
        response = {
            type: "error",
            message: "Server error. Please try again."
        }
    }
    return Promise.reject(response)
}

export const getReminders = async ({ queryKey }: any): Promise<ReminderType[]> => {
    const [_key, filter] = queryKey
    console.log('called get reminders', filter)
    try {
        const userId = localStorage.getItem('userId')
        const userToken = localStorage.getItem('token')
        if (!userToken || !userId) {
            return Promise.reject([])
        }
        const serverResponse: {
            data: {
                isSuccess: boolean
                reminders: ReminderType[]
            }
        } = await axios.get(`${BASE_PATH}/api/getReminders`, {
            params: {
                'userId': userId,
                'token': userToken,
                'filter': filter
            }
        })
        console.log('get reminders server response', serverResponse)
        const { isSuccess, reminders } = serverResponse.data
        if (isSuccess) {
            return Promise.resolve(reminders)
        }
        return Promise.reject([])
    }
    catch {
        return Promise.reject([])
    }
}

export const getReminder = async (reminderId: string): Promise<ReminderType> => {
    console.log('called get reminder :', reminderId)
    try {
        const userId = localStorage.getItem('userId')
        const userToken = localStorage.getItem('token')
        if (!userToken || !userId) {
            return Promise.reject({} as ReminderType)
        }
        const response: {
            data: {
                isSuccess: boolean
                reminder: ReminderType
            }
        } = await axios.get(`${BASE_PATH}/api/getReminder`, {
            params: {
                'userId': userId,
                'token': userToken,
                'reminderId': reminderId
            }
        })
        console.log('get reminder response: ', response.data);
        const { isSuccess, reminder } = response.data
        if (isSuccess) {
            return Promise.resolve(reminder)
        }
    }
    catch {
        console.error('[ERROR] error fetching reminder')
    }
    return Promise.reject({} as ReminderType)
}

export const addReminder = async (reminderData: ReminderType): Promise<any> => {
    console.log('called add reminder with data: ', reminderData)
    const userId = localStorage.getItem('userId')
    const userToken = localStorage.getItem('token')
    if (!userToken || !userId) {
        return Promise.reject(false)
    }
    const { remind } = reminderData
    let serverData = { ...reminderData, userId: userId }
    if (!remind) {
        serverData = { ...reminderData, userId: userId, reminderDate: '', reminderTime: '' }
    }
    try {
        const addResponse: {
            data: {
                isSuccess: boolean,
                id: string
            }
        } = await axios.post(`${BASE_PATH}/api/addReminder`, {
            serverData: serverData,
            token: userToken
        })
        console.log('add reminder server response', addResponse)
        if (addResponse.data.isSuccess) {
            return Promise.resolve(true)
        }
        return Promise.reject(false)
    }
    catch (ex) {
        return Promise.reject(false)
    }
}

export const updateReminder = async (reminderData: ReminderType): Promise<any> => {
    console.log('called update reminder with data: ', reminderData)
    const userId = localStorage.getItem('userId')
    const userToken = localStorage.getItem('token')
    if (!userToken || !userId) {
        return Promise.reject(false)
    }
    const { remind } = reminderData
    let serverData = { ...reminderData, userId: userId }
    if (!remind) {
        serverData = { ...reminderData, userId: userId, reminderDate: '', reminderTime: '' }
    }
    try {
        const updateResponse: {
            data: {
                isSuccess: boolean,
                id: string
            }
        } = await axios.post(`${BASE_PATH}/api/updateReminder`, {
            serverData: serverData,
            token: userToken
        })
        console.log('update reminder server response', updateResponse)
        if (updateResponse.data.isSuccess) {
            return Promise.resolve(true)
        }
        return Promise.reject(false)
    }
    catch (ex) {
        return Promise.reject(false)
    }
}

export const deleteReminder = async (reminderId: string): Promise<any> => {
    console.log('called delete reminder with id: ', reminderId)
    const userId = localStorage.getItem('userId')
    const userToken = localStorage.getItem('token')
    if (!userToken || !userId) {
        return Promise.reject(false)
    }
    try {
        const deleteResponse: {
            data: {
                isSuccess: boolean,
                id: string
            }
        } = await axios.post(`${BASE_PATH}/api/deleteReminder`, {
            token: userToken,
            userId: userId,
            reminderId: reminderId
        })
        console.log('delete reminder server response', deleteResponse)
        if (deleteResponse.data.isSuccess) {
            return Promise.resolve(true)
        }
        return Promise.reject(false)
    }
    catch (ex) {
        return Promise.reject(false)
    }
}