import { useRef, useState } from "react"
import ActionButton from "../components/ActionButton"
import Label from "../components/Label"
import Input from "../components/Input"
import { EditIcon } from "../resources/Icons"
import { useMutation, useQuery } from "@tanstack/react-query"
import { getProfile, updateProfile } from "../api/api"
import Loading from "../components/Loading"
import { GenericServerResponse, ProfileType } from "../types/types"
import ErrorPage from "./ErrorPage"
import LinkButton from "../components/LinkButton"
import Toast from "../components/Toast"

const UserAccount = () => {
    const [editEnabled, setEditEnabled] = useState(false)
    const [saveState, setSaveState] = useState({
        type: "",
        message: ""
    })
    const [name, setName] = useState("")

    const nameRef = useRef<HTMLInputElement>(null)

    const fetchProfile = async () => {
        const userProfile: ProfileType = await getProfile()
        if (userProfile) {
            setName(userProfile.name)
        }
        return userProfile
    }

    const getProfileQuery = useQuery({
        queryKey: ['getProfile'],
        queryFn: () => fetchProfile()
    })

    const processUpdateProfileSuccess = (mutationResponse: any) => {
        const response: GenericServerResponse = mutationResponse
        setSaveState({
            type: response.type,
            message: response.message
        })
        setEditEnabled(false)
    }

    const processUpdateProfileError = (mutationResponse: any) => {
        const response: GenericServerResponse = mutationResponse
        setSaveState({
            type: response.type,
            message: response.message
        })
        nameRef.current?.focus()
    }

    const updateProfileMutation = useMutation({
        mutationFn: (profileData: ProfileType) => updateProfile(profileData),
        onSuccess: processUpdateProfileSuccess,
        onError: processUpdateProfileError
    })

    const setNameState = (value: string) => {
        setSaveState({
            type: "",
            message: ""
        })
        setName(value)
    }

    const updateUserProfile = () => {
        if (!name) {
            setSaveState({
                type: "error",
                message: "Please enter value for Name."
            })
            nameRef.current?.focus()
            return false
        }
        updateProfileMutation.mutate({ name: name } as ProfileType)
    }

    if (getProfileQuery.isError) {
        return <ErrorPage />
    }

    const inputClassNormal = "bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500"
    const inputClassError = "bg-red-50 border border-red-500 text-red-900 text-m rounded-lg focus:ring-red-500 focus:border-red-500"

    return (
        <div>
            {
                getProfileQuery.isLoading &&
                <Loading />
            }
            <div className="flex flex-col items-center">
                <div className="w-full md:w-96 p-4 md:p-0 md:pt-4">
                    <div className="flex pb-4 justify-center">
                        <Label className={"title-primary"}>Your Profile</Label>
                    </div>
                    {
                        saveState.type === 'error' &&
                        <Toast
                            type={saveState.type}
                            message={saveState.message} />
                    }
                    {
                        saveState.type === 'success' &&
                        <Toast
                            type={saveState.type}
                            message={saveState.message} />
                    }
                    <div className="flex justify-end items-center gap-4">
                        <ActionButton
                            disabled={false}
                            callBack={() => {
                                setEditEnabled(true)
                                setTimeout(() => { nameRef.current?.focus() }, 1)
                            }}
                        >
                            {EditIcon}
                        </ActionButton>
                    </div>
                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900 after:content-['*'] after:text-red-500"}>Name</Label>
                        <Input
                            id={"name-input"}
                            value={name}
                            inputRef={nameRef}
                            disabled={!editEnabled}
                            className={saveState.type === 'error' && !name ? `${inputClassError} w-full p-2.5` : `${inputClassNormal} w-full p-2.5`}
                            callBack={setNameState} />
                    </div>
                    <div className="flex w-full justify-center">
                        <ActionButton
                            disabled={!editEnabled}
                            className={"btn-primary"}
                            callBack={updateUserProfile}>
                            Update
                        </ActionButton>
                    </div>
                    <div className="flex w-full justify-center pt-6">
                        <LinkButton
                            href="/resetpassword?authenticated=true"
                            className={"btn-primary"}
                        >
                            Change Password
                        </LinkButton>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserAccount