interface InputAreaProps {
    id: string
    value?: string
    disabled: boolean
    className: string
    callBack: (val: string) => void
}
const InputArea = ({ ...props }: InputAreaProps) => {
    return (
        <textarea
            id={props.id}
            rows={4}
            className={props.className}
            value={props.value}
            placeholder="Write your thoughts here..."
            disabled={props.disabled}
            onChange={(e) => props.callBack && props.callBack(e.target?.value)}
        >
        </textarea>
    )
}
export default InputArea