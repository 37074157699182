import { ReactNode } from "react"

interface LabelProps {
    className: string
    children: ReactNode
}
const Label = ({...props}: LabelProps) => {
    return (
        <label className={props.className}>{props.children}</label>
    )
}
export default Label