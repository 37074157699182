import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import ErrorPage from './pages/ErrorPage';
import { getReminder } from './api/api';
import ReminderDetailPage from './pages/ReminderDetailPage';
import ProtectedRoute from './util/ProtectedRoute';
import AddReminderPage from './pages/AddReminderPage';
import SignUpPage from './pages/SignUpPage';
import UserAccount from './pages/UserAccount';
import Layout from './components/Layout';
import AuthProvider from './util/AuthProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PasswordResetPage from './pages/PasswordResetPage';
import VerifyAccount from './pages/VerifyAccount';

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: "/signup",
    element:
      <Layout showFilter={false} showSideBar={false} showAvatar={false}>
        <SignUpPage />
      </Layout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/login",
    element:
      <AuthProvider>
        <Layout showFilter={false} showSideBar={false} showAvatar={false}>
          <LoginPage />
        </Layout>
      </AuthProvider>,
    errorElement: <ErrorPage />
  },
  {
    path: "/resetpassword",
    element:
      <Layout showFilter={false} showSideBar={false} showAvatar={false}>
        <PasswordResetPage />
      </Layout>,
    errorElement: <ErrorPage />
  },
  {
    path: "/verifyAccount",
    element:
      <AuthProvider>
        <ProtectedRoute>
          <Layout showFilter={false} showSideBar={false} showAvatar={false}>
            <VerifyAccount />
          </Layout>
        </ProtectedRoute>
      </AuthProvider>
  },
  {
    path: "/",
    element:
      <AuthProvider>
        <ProtectedRoute>
          <Layout showFilter={true} showSideBar={true} showAvatar={true}>
            <HomePage />
          </Layout>
        </ProtectedRoute>
      </AuthProvider>
  },
  {
    path: "/account",
    element:
      <AuthProvider>
        <ProtectedRoute>
          <Layout showFilter={false} showSideBar={true} showAvatar={true}>
            <UserAccount />
          </Layout>
        </ProtectedRoute>
      </AuthProvider>
  },
  {
    path: "/reminders/:reminderId",
    element:
      <AuthProvider>
        <ProtectedRoute>
          <Layout showFilter={false} showSideBar={true} showAvatar={true}>
            <ReminderDetailPage />
          </Layout>
        </ProtectedRoute>
      </AuthProvider>
  },
  {
    path: "/reminders/add",
    element:
      <AuthProvider>
        <ProtectedRoute>
          <Layout showFilter={false} showSideBar={true} showAvatar={true}>
            <AddReminderPage />
          </Layout>
        </ProtectedRoute>
      </AuthProvider>
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
