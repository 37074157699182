import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input";
import Label from "../components/Label";
import ActionButton from "../components/ActionButton";
import { useAuth } from "../util/AuthProvider";
import Toast from "../components/Toast";
import { doLogin } from "../api/api";
import MessagePane from "../components/MessagePane";
import { useMutation } from "@tanstack/react-query";
import Loading from "../components/Loading";
import MaskableInput from "../components/MaskableInput";

interface LogInResponse {
    type: string
    userId: string
    verified: boolean
    token: string
    message: string
}

const LoginPage = () => {
    const navigate = useNavigate()
    const { setSession } = useAuth()

    const [loginState, setLoginState] = useState<{ type: string, message: string }>({
        type: "",
        message: ""
    })
    const emailRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const signUpState = localStorage.getItem('signUpState')

    useEffect(() => {
        if (signUpState) {
            setLoginState({
                type: "success",
                message: `${signUpState} Please Log In`
            })
            const timer = setTimeout(() => {
                localStorage.removeItem('signUpState')
            }, 1000)
            return () => clearTimeout(timer)
        }
        emailRef.current?.focus()
    }, [signUpState])

    const processLoginSuccess = (mutationResponse: any) => {
        const response: LogInResponse = mutationResponse
        setSession(response.userId, response.token, response.verified)
        if (response.verified) {
            return navigate('/')
        } else {
            return navigate('/verifyAccount')
        }
    }

    const processLoginError = (mutationResponse: any) => {
        const response: LogInResponse = mutationResponse
        console.log('clearing pass');
        setPassword("")
        setLoginState({
            type: response.type,
            message: response.message
        })
        emailRef.current?.focus()
    }

    const logInMutation = useMutation({
        mutationFn: async (logInData: {
            email: string
            password: string
        }) => await doLogin(logInData),
        onSuccess: processLoginSuccess,
        onError: processLoginError
    })

    const setEmailState = (value: string) => {
        setLoginState({
            type: "",
            message: ""
        })
        setEmail(value)
    }
    const setPasswordState = (value: string) => {
        setLoginState({
            type: "",
            message: ""
        })
        setPassword(value)
    }

    const setLogin = async () => {
        if (!email) {
            setLoginState({
                type: "error",
                message: "Please enter your email"
            })
            emailRef.current?.focus()
            return
        }
        if (!password) {
            setLoginState({
                type: "error",
                message: "Please enter your password"
            })
            passwordRef.current?.focus()
            return
        }
        console.log('getting token from server')
        logInMutation.mutate({
            email,
            password
        })
    }

    return (
        <div>
            {
                logInMutation.isPending && <Loading />
            }
            <div className="flex flex-col items-center">
                <div className="w-full sm:w-96 py-5 md:py-10 md:px-0 px-2">
                    <div className="pb-4 flex justify-center">
                        <Label className={"title-primary"}>Log In to access your space</Label>
                    </div>
                    {
                        (loginState.type === 'success' || loginState.type === 'error') &&
                        <MessagePane
                            type={loginState.type}
                            message={loginState.message}
                        />
                    }
                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Email</Label>
                        <Input id="login-email"
                            disabled={false}
                            inputRef={emailRef}
                            callBack={setEmailState}
                            className={"bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"}
                        />
                    </div>
                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Password</Label>
                        <MaskableInput id={"login-password"}
                            disabled={false}
                            value={password}
                            inputRef={passwordRef}
                            type="password"
                            autoComplete="off"
                            callBack={setPasswordState}
                            className={"bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"}
                        />
                    </div>

                    <div className="flex w-full justify-center">
                        <ActionButton
                            disabled={false}
                            className={"btn-primary"}
                            callBack={setLogin}>
                            Login
                        </ActionButton>
                    </div>
                    <div className="flex justify-center">
                        <a href="/signup">
                            <Label className={"text-m text-blue-600"}>
                                Don't have an account? Sign Up
                            </Label>
                        </a>
                    </div>
                    <div className="flex justify-center">
                        <a href="/resetpassword">
                            <Label className={"text-sm text-blue-600"}>
                                Forgot your password?
                            </Label>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage