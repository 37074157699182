import { ReactNode } from "react"

interface LinkButtonProps{
    href: string
    className?: string,
    children?: ReactNode
}
const LinkButton = ({ href, className, children}: LinkButtonProps) =>{
    return (
        <a href={href} className={className}>
            {children}
        </a>
    )
}
export default LinkButton