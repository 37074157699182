import { useEffect, useRef, useState } from "react"
import Input from "../components/Input"
import { addReminder } from "../api/api"
import moment from "moment"
import InputArea from "../components/InputArea"
import DateInput from "../components/DateInput"
import RadioGroup from "../components/RadioGroup"
import Label from "../components/Label"
import ActionButton from "../components/ActionButton"
import Toast from "../components/Toast"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { ReminderType } from "../types/types"
import Loading from "../components/Loading"
import { formatDateWithDelta } from "../util/util"

const AddReminderPage = () => {
    const navigate = useNavigate()

    const processSuccess = () => {
        localStorage.setItem('saveState', "saved")
        return navigate('/')
    }
    const processError = () => {
        setSaveState({
            type: "error",
            message: "The record cannot be saved. Please try again."
        })
    }

    const addReminderMutation = useMutation({
        mutationFn: (reminderData: ReminderType) => addReminder(reminderData),
        onSuccess: processSuccess,
        onError: processError
    })

    const formattedDateTime = formatDateWithDelta(new Date(), 30) // get 30 mins ahead of UTC
    console.log('formattedDateTime: ', formattedDateTime)
    const [currentDate, currentTime] = formattedDateTime.split('T')

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [type, setType] = useState("reminder")
    const [remind, setRemind] = useState(false)
    const [reminderDate, setReminderDate] = useState(currentDate)
    const [reminderTime, setReminderTime] = useState(currentTime)
    const [expiryDate, setExpiryDate] = useState("")
    const [saveState, setSaveState] = useState<{ type: string, message: string }>(
        {
            type: "",
            message: ""
        }
    )

    const titleRef = useRef<HTMLInputElement>(null)
    const reminderDateRef = useRef<HTMLInputElement>(null)
    const reminderTimeRef = useRef<HTMLInputElement>(null)

    const setTitleState = (value: string) => {
        setSaveState({
            type: "",
            message: ""
        })
        setTitle(value)
    }

    const setDescriptionState = (value: string) => {
        setSaveState({
            type: "",
            message: ""
        })
        setDescription(value)
    }

    const setReminderDateState = (value: string) => {
        setSaveState({
            type: "",
            message: ""
        })
        console.log('setting reminder date: ', value)
        
        setReminderDate(value)
    }

    const setReminderTimeState = (value: string) => {
        setSaveState({
            type: "",
            message: ""
        })
        setReminderTime(value)
    }

    const doValidate = () => {
        if (!title) {
            setSaveState({
                type: "error",
                message: "Please enter value for Title"
            })
            titleRef.current?.focus()
            return false
        }
        if (remind) {
            if (!reminderDate) {
                setSaveState({
                    type: "error",
                    message: "Please enter value for Reminder Date"
                })
                reminderDateRef.current?.focus()
                return false
            }
            if (!reminderTime) {
                setSaveState({
                    type: "error",
                    message: "Please enter value for Reminder Time"
                })
                reminderTimeRef.current?.focus()
                return false
            }
            console.log('reminderDate', reminderDate)
            console.log('reminderTime', reminderTime)
            const currentDate = new Date()
            currentDate.setMinutes(currentDate.getMinutes() + 30) // get 30 mins ahead of UTC
            const utcCurrentTime = currentDate.toISOString()
            console.log('utcCurrentTime', utcCurrentTime)
            const formattedReminderTime = `${reminderDate}T${reminderTime}:00.000`.replaceAll('/', '-')
            const utcReminderTime = new Date(formattedReminderTime).toISOString()
            console.log('utcReminderTime', utcReminderTime)
            if (utcReminderTime < utcCurrentTime) {
                setSaveState({
                    type: "error",
                    message: "Please enter a time at least 30 mins greater than current time"
                })
                reminderDateRef.current?.focus()
                return false
            }
        }
        return true
    }

    const saveReminder = async () => {
        const validated = doValidate()
        if (validated) {
            const formattedReminderTime = `${reminderDate}T${reminderTime}:00.000`.replaceAll('/', '-')
            const utcReminderTime = new Date(formattedReminderTime).toISOString()
            const formattedUtcTime = moment(utcReminderTime).utc().format('YYYY-MM-DDTHH:mm')
            console.log('formattedUtcTime: ', formattedUtcTime)
            const [_reminderDate, _reminderTime] = formattedUtcTime.split('T')
            addReminderMutation.mutate({
                id: '',
                title,
                description,
                type,
                expiryDate,
                remind,
                reminderDate: _reminderDate,
                reminderTime: _reminderTime,
                createdAt: ''
            })
        }
    }

    const radioDataList = [
        {
            id: "radio-reminder",
            value: "reminder",
            type: type,
            title: "Reminder",
            disabled: false,
            callBack: setType
        },
        {
            id: "radio-note",
            value: "note",
            type: type,
            title: "Note",
            disabled: false,
            callBack: setType
        }
    ]
    const inputClassNormal = "bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500"
    const inputClassError = "bg-red-50 border border-red-500 text-red-900 text-m rounded-lg focus:ring-red-500 focus:border-red-500"

    return (
        <div>
            {
                addReminderMutation.isPending && <Loading />
            }
            <div className="flex flex-col items-center">
                <div className="w-full sm:w-96 p-4 md:p-0 md:pt-4">
                    <div className="pb-4 text-center">
                        <Label className={"title-primary"}>Add a new reminder</Label>
                    </div>
                    {
                        saveState.type === 'error' &&
                        <Toast
                            type={saveState.type}
                            message={saveState.message} />
                    }
                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Type</Label>
                        <RadioGroup radioGroupList={radioDataList} />
                    </div>
                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900 after:content-['*'] after:text-red-500"}>Title</Label>
                        <Input
                            id={"add-reminder-title"}
                            inputRef={titleRef}
                            disabled={false}
                            className={saveState.type === 'error' && !title ? `${inputClassError} w-full p-2.5` : `${inputClassNormal} w-full p-2.5`}
                            callBack={setTitleState}
                        />
                    </div>
                    <div className="pb-2">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Description</Label>
                        <InputArea
                            id={"description-input"}
                            disabled={false}
                            className={"p-2.5 w-full text-m text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"}
                            callBack={setDescriptionState} />
                    </div>
                    {
                        type === "reminder" &&
                        <div className="pb-3">
                            <div className="pb-4">
                                <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Expiry</Label>
                                <DateInput
                                    id={"expiry-date"}
                                    value={expiryDate}
                                    min={"2023-01-01"}
                                    max={"2030-12-31"}
                                    disabled={false}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-2/5 p-2.5"
                                    callBack={setExpiryDate}
                                />
                            </div>
                            <div className="pb-3">
                                <div className="flex items-center">
                                    <input id="checked-checkbox"
                                        type="checkbox"
                                        checked={remind}
                                        disabled={false}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                        onChange={(e) => setRemind(e.target.checked)}
                                    />
                                    <Label className={"ms-2 text-m font-medium text-gray-900"}>
                                        Do you want us to remind you?
                                    </Label>
                                </div>
                            </div>
                            {
                                remind &&
                                <div>
                                    <div className="pb-2">
                                        <Label className={"block mb-1 text-lg font-medium text-gray-900 after:content-['*'] after:text-red-500"}>When?</Label>
                                        <div className="flex gap-x-2">
                                            <DateInput
                                                id={"reminder-date"}
                                                value={reminderDate}
                                                min={currentDate}
                                                max={"2030-12-31"}
                                                dateInputRef={reminderDateRef}
                                                disabled={false}
                                                className={saveState.type === 'error' && !reminderDate ? `${inputClassError} w-2/5 p-2.5` : `${inputClassNormal} w-2/5 p-2.5`}
                                                callBack={setReminderDateState}
                                            />
                                            <input
                                                type="time"
                                                id="time"
                                                min="09:00"
                                                max="18:00"
                                                value={reminderTime}
                                                ref={reminderTimeRef}
                                                disabled={false}
                                                className={saveState.type === 'error' && !reminderTime ? `${inputClassError} w-2/5 p-2.5 leading-none` : `${inputClassNormal} w-2/5 p-2.5 leading-none`}
                                                onChange={(e) => setReminderTimeState(e.target?.value)}
                                            />
                                        </div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <div className="flex w-full justify-center">
                        <ActionButton
                            disabled={false}
                            className={"btn-primary"}
                            callBack={saveReminder}>
                            Save
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddReminderPage