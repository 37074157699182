import axios from "axios"
import { createContext, useContext, useEffect, useMemo, useState } from "react"

interface Props {
    children: React.ReactNode
}

const AuthContext = createContext<{ session: { userId: string, token: string, verified: boolean }, setSession: (userId: string, token: string, verified: boolean) => void }>({
    session: {
        userId: "",
        token: "",
        verified: false
    },
    setSession: (userId: string, token: string) => { }
})

const AuthProvider = ({ children }: Props) => {
    const [session, setSession_] = useState<{ userId: string, token: string, verified: boolean }>({
        userId: localStorage.getItem("userId") || "",
        token: localStorage.getItem("token") || "",
        verified: (localStorage.getItem("verified") === "true") || false,
    })

    const setSession = (userId: string, token: string, verified: boolean) => {
        localStorage.setItem('token', token)
        localStorage.setItem('userId', userId)
        localStorage.setItem('verified', verified.toString())
        axios.defaults.headers.common["Authorization"] = "Bearer " + token
        setSession_({
            userId,
            token,
            verified
        })
    }

    useEffect(() => {
        if (session?.userId && session?.token) {
            console.log('setting axios headers in');
            axios.defaults.headers.common["Authorization"] = "Bearer " + session.token
            // localStorage.setItem('userId', session.userId)
            // localStorage.setItem('token', session.token)
        } else {
            delete axios.defaults.headers.common["Authorization"]
            localStorage.removeItem('userId')
            localStorage.removeItem('token')
            localStorage.removeItem('verified')
        }
    }, [session.userId, session.token])

    const contextValue = useMemo(() => ({
        session: session,
        setSession: setSession
    }), [session.userId, session.token, session.verified])

    return (
        <AuthContext.Provider
            value={contextValue}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}

export default AuthProvider