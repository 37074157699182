import { useEffect, useState } from "react"
import NavBar from "./NavBar"
import SideBar from "./SideBar"
import { useNavigate } from "react-router-dom"

interface LayoutProps {
    showFilter: boolean
    showSideBar: boolean
    showAvatar: boolean
    children: React.ReactNode
}

const Layout = ({ ...props }: LayoutProps) => {
    const isMobile = window.innerWidth <= 768
    const showSidebar = props.showSideBar && !isMobile
    const [sidebar, setSidebar] = useState(showSidebar)

    const updateSideBar = () => {
        setSidebar(!sidebar)
    }

    useEffect(() => {
        setSidebar(showSidebar)
    }, [showSidebar])

    return (
        <div>
            <NavBar
                showSideBar={props.showSideBar}
                showAvatar={props.showAvatar}
                callBack={updateSideBar}
            >
            </NavBar>
            <SideBar
                showSideBar={sidebar}
                showFilter={props.showFilter}
                callBack={updateSideBar}
            >
                {props.children}
            </SideBar>
        </div>
    )
}
export default Layout