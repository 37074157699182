import { useNavigate } from "react-router-dom"
import { generatePasscode, getProfile, verifyAccount } from "../api/api"
import { GenericServerResponse, ProfileType } from "../types/types"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useRef, useState } from "react"
import Loading from "../components/Loading"
import Label from "../components/Label"
import MessagePane from "../components/MessagePane"
import Input from "../components/Input"
import ActionButton from "../components/ActionButton"
import { useAuth } from "../util/AuthProvider"

const VerifyAccount = () => {
    const navigate = useNavigate()
    const { session, setSession } = useAuth()
    let userId = localStorage.getItem("userId")

    const getUserProfile = async () => {
        const userProfile: ProfileType = await getProfile()
        if (userProfile) {
            setEmail(userProfile.email)
            setAccountState("initial")
            setPageState({
                type: "success",
                message: "Please proceed to verify your account."
            })
        }
        return userProfile
    }
    const getProfileQuery = useQuery({
        queryKey: ['getProfileQuery'],
        queryFn: () => getUserProfile(),
        enabled: !!userId,
        refetchOnWindowFocus: false
    })
    const [email, setEmail] = useState("")
    const [passcode, setPasscode] = useState("")
    const [accountState, setAccountState] = useState("initial")
    const [pageState, setPageState] = useState<{ type: string, message: string }>({
        type: "",
        message: ""
    })
    const emailRef = useRef<HTMLInputElement>(null)
    const passcodeRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        emailRef.current?.focus()
    }, [])

    const processVerifyAccountSuccess = () => {
        setSession(session.userId, session.token, true)
        return navigate('/')
    }

    const processVerifyAccountError = (mutationResponse: any) => {
        console.log('mutationResponse error', mutationResponse)
        const response: GenericServerResponse = mutationResponse
        setPasscode("")
        setPageState({
            type: response.type,
            message: response.message
        })
        passcodeRef.current?.focus()
    }

    const processGeneratePasscodeSuccess = (mutationResponse: any) => {
        console.log('mutationResponse success', mutationResponse)
        const response: GenericServerResponse = mutationResponse
        setPageState({
            type: response.type,
            message: response.message
        })
        setAccountState("submitted")

        setTimeout(() => {
            passcodeRef.current?.focus()
        }, 100)
    }

    const processGeneratePasscodeError = (mutationResponse: any) => {
        console.log('mutationResponse error', mutationResponse)
        const response: GenericServerResponse = mutationResponse
        setPasscode("")
        setPageState({
            type: response.type,
            message: response.message
        })
        setAccountState("initial")
        emailRef.current?.focus()
    }

    const generatePasscodeMutation = useMutation({
        mutationFn: async (email: string) => await generatePasscode(email),
        onSuccess: processGeneratePasscodeSuccess,
        onError: processGeneratePasscodeError
    })

    const verifyAccountMutation = useMutation({
        mutationFn: async (verifyAccountData: {
            email: string
            passcode: string
        }) => await verifyAccount(verifyAccountData),
        onSuccess: processVerifyAccountSuccess,
        onError: processVerifyAccountError
    })

    const setEmailState = (value: string) => {
        setPageState({
            type: "",
            message: ""
        })
        setEmail(value)
    }

    const setPasscodeState = (value: string) => {
        setPageState({
            type: "",
            message: ""
        })
        setPasscode(value)
    }

    const generateUserPasscode = async () => {
        if (!email) {
            setPageState({
                type: "error",
                message: "Please enter your email."
            })
            emailRef.current?.focus()
            return
        }
        console.log('generating password')
        generatePasscodeMutation.mutate(email)
    }

    const verifyUserAccount = async () => {
        if (!email) {
            setPageState({
                type: "error",
                message: "Please enter your email."
            })
            emailRef.current?.focus()
            return
        }
        if (!passcode) {
            setPageState({
                type: "error",
                message: "Please enter the passcode."
            })
            passcodeRef.current?.focus()
            return
        }
        console.log('verifying account')
        verifyAccountMutation.mutate({
            email,
            passcode
        })

    }

    return (
        <div>
            {
                (getProfileQuery.isLoading || generatePasscodeMutation.isPending || verifyAccountMutation.isPending) && <Loading />
            }
            <div className="flex flex-col items-center">
                <div className="w-full sm:w-96 py-5 md:py-10 md:px-0 px-2">
                    <div className="pb-4 flex justify-center">
                        <Label className={"title-primary"}>Verify your account</Label>
                    </div>
                    {
                        (pageState.type === 'success' || pageState.type === 'error') &&
                        <MessagePane
                            type={pageState.type}
                            message={pageState.message}
                        />
                    }

                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Email</Label>
                        <Input id="login-email"
                            disabled={false}
                            value={email}
                            inputRef={emailRef}
                            className={"bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"}
                            callBack={setEmailState}
                        />
                    </div>

                    {
                        accountState === "submitted" &&
                        <div className="pb-4">
                            <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Code</Label>
                            <Input id={"verify-passcode"}
                                disabled={false}
                                value={passcode}
                                inputRef={passcodeRef}
                                autoComplete="off"
                                className={"bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"}
                                callBack={setPasscodeState}
                            />
                        </div>
                    }

                    {
                        accountState === "submitted" &&
                        <div className="flex w-full justify-center">
                            <ActionButton
                                disabled={false}
                                className={"btn-primary"}
                                callBack={verifyUserAccount}>
                                Verify
                            </ActionButton>
                        </div>
                    }
                    {
                        <div className="flex w-full justify-center">
                            <ActionButton
                                disabled={false}
                                className={"btn-primary"}
                                callBack={generateUserPasscode}>
                                {accountState === "initial" ? 'Send Code' : 'Resend Code'}
                            </ActionButton>
                        </div>
                    }

                    <div className="flex justify-center">
                        <a href="/signup">
                            <Label className={"text-m text-blue-600"}>
                                Don't have an account? Sign Up
                            </Label>
                        </a>
                    </div>
                    <div className="flex justify-center">
                        <a href="/login">
                            <Label className={"text-sm text-blue-600"}>
                                Already have an account? Log In
                            </Label>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VerifyAccount