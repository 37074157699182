import { useEffect, useState } from "react"
import { CrossIcon, DangerIcon, checkIcon } from "../resources/Icons"

interface ToastProps {
    type: string
    message: string
}
const Toast = ({ type, message }: ToastProps) => {
    const [showToast, setShowToast] = useState(type === "error" || type === "success")
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowToast(false)
        }, 4000)
        return () => clearTimeout(timer)
    })
    if (!showToast) {
        return null
    }
    return (
        <div id="toast-danger" className="fixed flex items-center w-full max-w-xs p-4 mb-4 text-gray-800 bg-white rounded-lg shadow z-50">
            {
                type === "error" &&
                <div className="inline-flex items-center justify-center flex-shrink-0 w-10 h-10 text-red-500 bg-red-100 rounded-lg">
                    {DangerIcon}
                </div>
            }
            {
                type === "success" &&
                <div className="inline-flex items-center justify-center flex-shrink-0 w-10 h-10 text-green-500 bg-green-100 rounded-lg">
                    {checkIcon}
                </div>
            }
            <div className="ms-3 text-sm font-normal">{message}</div>
            <button type="button"
                className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-600 rounded-lg p-1.5 inline-flex items-center justify-center h-8 w-8"
                onClick={() => setShowToast(false)}
            >
                {CrossIcon}
            </button>
        </div>
    )
}
export default Toast