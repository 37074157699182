import { ReactNode } from "react"

interface ActionButtonProps {
    className?: string
    disabled: boolean
    callBack?: () => void
    children?: ReactNode
}
const ActionButton = ({ ...props }: ActionButtonProps) => {
    return (
        <button
            type="button"
            onClick={() => props.callBack && props.callBack()}
            className={props.className}
            disabled={props.disabled}>
            {props.children}
        </button>
    )
}
export default ActionButton