import Tile from "../components/Tile";
import { FilterType, ReminderType } from "../types/types";
import Pagination from "../components/Pagination";
import { useContext, useEffect, useState } from "react";
import Toast from "../components/Toast";
import { useQuery } from '@tanstack/react-query'
import { getReminders } from "../api/api";
import Loading from "../components/Loading";
import ErrorPage from "./ErrorPage";
import { AddDocumentIconLarge, AddDocumentIconSmall } from "../resources/Icons";
import { useFilter } from "../components/SideBar";

const HomePage = () => {
    const filter: {filter: FilterType}  = useFilter()
    console.log('filter :', filter.filter)
    
    const remindersQuery = useQuery({
        queryKey: ['getReminders', filter.filter],
        queryFn: getReminders
    })

    const [toast, showToast] = useState(false)
    const saveState = localStorage.getItem('saveState')

    useEffect(() => {
        console.log('useEffect called', saveState);

        if (saveState) {
            showToast(true)
            const timer = setTimeout(() => {
                localStorage.removeItem('saveState')
            }, 1000)
            return () => clearTimeout(timer)
        }
    }, [saveState])


    if (remindersQuery.isError) {
        return <ErrorPage />
    }

    const reminders: ReminderType[] = remindersQuery.data || []
    console.log('reminders from query', reminders)

    const reminderTiles = reminders.map(reminder => {
        return (
            <a className="flex w-full md:w-auto justify-center" key={reminder.id} href={`reminders/${reminder.id}`}>
                <Tile {...reminder}></Tile>
            </a>
        )
    })

    return (
        <div>
            {
                remindersQuery.isLoading &&
                <Loading></Loading>
            }
            <div className="p-4 md:pl-64 w-full h-full">
                {
                    toast &&
                    <Toast
                        type={"success"}
                        message={`Record ${saveState} successfully`} />
                }
                <div className="flex flex-col items-center justify-center h-full">
                    {
                        reminderTiles.length > 0 &&
                        <a href="/reminders/add" className="flex justify-end p-1 w-full md:hidden mb-4">
                            {AddDocumentIconSmall}
                        </a>
                    }
                    {
                        reminderTiles.length > 0 && <div className="flex flex-row flex-wrap gap-3 w-full">
                            {reminderTiles}

                        </div>
                    }
                    {
                        reminderTiles.length <= 0 &&
                        <a href="/reminders/add" className="border rounded-lg bg-gray-50">
                            <div className="p-12">
                                {AddDocumentIconLarge}
                            </div>
                        </a>
                    }
                    {
                        reminderTiles.length > 0 &&
                        <div className="fixed bottom-6">
                            <Pagination></Pagination>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default HomePage