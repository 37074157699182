import { useState } from "react"
import Input from "./Input"
import { CrossEyeIcon, EyeIcon } from "../resources/Icons"
import ActionButton from "./ActionButton"

interface MaskableInputProps {
    id: string
    value?: string
    inputRef?: React.RefObject<HTMLInputElement>
    disabled: boolean
    type?: string
    autoComplete?: string
    className: string
    callBack?: (val: any) => void
}

const MaskableInput = ({ ...props }: MaskableInputProps) => {
    const [mask, setMask] = useState(props.type && props.type === "password")
    const inputProps = { ...props, type: mask ? "password" : "text" }
    return (
        <div className="mb-4 flex">
            <Input {...inputProps} />
            <div className="flex justify-around items-center">
                {
                    mask &&
                    <ActionButton callBack={() => setMask(false)} className="absolute mr-10 bg-gray-50" disabled={false}>
                        {CrossEyeIcon}
                    </ActionButton>
                }
                {
                    !mask &&
                    <div onClick={() => setMask(true)} className="absolute mr-10 bg-gray-50">
                        {EyeIcon}
                    </div>
                }
            </div>
        </div>

    )
}
export default MaskableInput