interface MessagePaneProps {
    type: string
    message: string
}

const MessagePane = ({ type, message }: MessagePaneProps) => {
    let className = 'text-center text-red-500'
    if(type === "success"){
        className = 'text-center text-blue-600'
    }
    return (
        <div className={className}>
            {message}
        </div>
    )
}
export default MessagePane