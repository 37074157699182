import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input";
import Label from "../components/Label";
import ActionButton from "../components/ActionButton";
import MessagePane from "../components/MessagePane";
import { doSignUp } from "../api/api";
import { useMutation } from "@tanstack/react-query";
import Loading from "../components/Loading";
import MaskableInput from "../components/MaskableInput";

interface SignUpResponse {
    type: string
    message: string
}

const SignUpPage = () => {
    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [signUpState, setSignUpState] = useState<{ type: string, message: string }>({
        type: "",
        message: ""
    })
    const nameRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        nameRef.current?.focus()
    }, [])

    const processSignUpSuccess = (mutationResponse: any) => {
        console.log('mutationResponse success', mutationResponse)
        const response: SignUpResponse = mutationResponse
        localStorage.setItem('signUpState', "Your account is created.")
        return navigate('/login')
    }
    const processSignUpError = (mutationResponse: any) => {
        console.log('mutationResponse error', mutationResponse)
        const response: SignUpResponse = mutationResponse
        setPassword("")
        setSignUpState({
            type: response.type,
            message: response.message
        })
        nameRef.current?.focus()
    }

    const signUpMutation = useMutation({
        mutationFn: async (signUpData: {
            name: string
            email: string
            password: string
        }) => await doSignUp(signUpData),
        onSuccess: processSignUpSuccess,
        onError: processSignUpError
    })

    const setNameState = (value: string) => {
        setSignUpState({
            type: "",
            message: ""
        })
        setName(value)
    }

    const setEmailState = (value: string) => {
        setSignUpState({
            type: "",
            message: ""
        })
        setEmail(value)
    }

    const setPasswordState = (value: string) => {
        setSignUpState({
            type: "",
            message: ""
        })
        setPassword(value)
    }

    const setSignUp = async () => {
        if (!name) {
            setSignUpState({
                type: "error",
                message: "Please enter your name"
            })
            nameRef.current?.focus()
            return
        }
        if (!email) {
            setSignUpState({
                type: "error",
                message: "Please enter your email"
            })
            emailRef.current?.focus()
            return
        }
        if (!password) {
            setSignUpState({
                type: "error",
                message: "Please enter your password"
            })
            passwordRef.current?.focus()
            return
        }
        console.log('signing you up')
        signUpMutation.mutate({
            name,
            email,
            password
        })
        /*const signUpResponse: {
            type: string
            message: string
        } = await doSignUp({
            name,
            email,
            password
        })
        if (signUpResponse.type === "error") {
            setSignUpState({
                type: "error",
                message: signUpResponse.message
            })
            return
        }
        navigate('/login')*/
    }

    return (
        <div>
            {
                signUpMutation.isPending && <Loading />
            }
            <div className="flex flex-col items-center">
                <div className="w-full sm:w-96 py-5 md:py-10 md:px-0 px-2">
                    <div className="pb-4 flex justify-center">
                        <Label className={"title-primary"}>Create an account</Label>
                    </div>
                    {
                        signUpState.type === 'error' &&
                        <MessagePane
                            type={signUpState.type}
                            message={signUpState.message}
                        />
                    }
                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Name</Label>
                        <Input id="login-name"
                            disabled={false}
                            inputRef={nameRef}
                            className={"bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"}
                            callBack={setNameState}
                        />
                    </div>
                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Email</Label>
                        <Input id="login-email"
                            disabled={false}
                            inputRef={emailRef}
                            className={"bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"}
                            callBack={setEmailState}
                        />
                    </div>
                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Password</Label>
                        <MaskableInput id={"login-password"}
                            disabled={false}
                            value={password}
                            inputRef={passwordRef}
                            type="password"
                            autoComplete="off"
                            className={"bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"}
                            callBack={setPasswordState}
                        />
                    </div>

                    <div className="flex w-full justify-center">
                        <ActionButton
                            disabled={false}
                            className={"btn-primary"}
                            callBack={setSignUp}>
                            Sign Up
                        </ActionButton>
                    </div>
                    <div className="flex justify-center">
                        <a href="/login">
                            <Label className={"text-m text-blue-600"}>
                                Already have an account? Log In
                            </Label>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpPage