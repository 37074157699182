import { useNavigate } from "react-router-dom"
import { CrossIcon, FilterVerticalIcon } from "../resources/Icons"
import ActionButton from "./ActionButton"
import Avatar from "./Avatar"
import DateInput from "./DateInput"
import Label from "./Label"
import LinkButton from "./LinkButton"
import { createContext, useContext, useMemo, useState } from "react"
import { FilterType } from "../types/types"

const FilterContext = createContext<{ filter: FilterType }>({
    filter: {
        type: [],
        expiryDate: {
            from: "",
            to: ""
        }
    }
})

const SideBar = ({ ...props }) => {
    const [filter, setFilter] = useState<FilterType>({
        type: [],
        expiryDate: {
            from: "",
            to: ""
        }
    })
    const [typeFilter, setTypeFilter] = useState<string[]>([])
    const [dateFilter, setDateFilter] = useState<{ [type: string]: string }>({
        from: "",
        to: ""
    })
    const navigate = useNavigate()
    const setFilterStatus = (type: string) => {
        console.log('typeFilter', typeFilter);
        console.log('dateFilter', dateFilter);
        if (type === "apply") {
            setFilter({
                type: typeFilter,
                expiryDate: dateFilter
            })
        } else {
            setTypeFilter([])
            setDateFilter({
                from: "",
                to: ""
            })
            setFilter({
                type: [],
                expiryDate: {
                    from: "",
                    to: ""
                }
            })
        }
    }
    const setTypeFilterState = (checked: boolean, type: string) => {
        console.log('set type filter: ', checked)
        let currentFilter = [...typeFilter]
        if (checked) {
            currentFilter.push(type)
        } else {
            currentFilter = currentFilter.filter(value => value !== type)
        }
        console.log('currentFilter', currentFilter)
        setTypeFilter(currentFilter)
    }
    const setFromDateFilter = (value: string) => {
        console.log('set from date filter: ', value)
        let currentFilter = { ...dateFilter }
        currentFilter["from"] = value
        setDateFilter(currentFilter)
    }
    const setToDateFilter = (value: string) => {
        console.log('set to date filter: ', value)
        let currentFilter = { ...dateFilter }
        currentFilter["to"] = value
        setDateFilter(currentFilter)
    }
    const doLogOut = () => {
        localStorage.clear()
        navigate("/login")
    }

    const contextValue = useMemo(() => ({
        filter: filter
    }), [filter])
    return (
        <div>
            {
                props.showSideBar && <div id="default-sidebar" className="fixed left-0 md:top-auto top-0 z-50 w-60 h-full transition-transform">
                    <div className="h-full overflow-y-auto bg-gray-100 border-r border-gray-200">
                        <div className="flex md:hidden hover:bg-gray-100 bg-gray-200 border border-gray-300">
                            <div className="flex justify-between w-full items-center font-medium">
                                <div>
                                    <a href="/account" className="flex md:hidden py-2 px-3 ms-3">
                                        <Avatar />
                                    </a>
                                </div>
                                <div>
                                    <ActionButton
                                        disabled={false}
                                        className="pr-4"
                                        callBack={props.callBack}>
                                        {CrossIcon}
                                    </ActionButton>
                                </div>
                            </div>
                        </div>
                        {
                            props.showFilter &&
                            <ul className="space-y-2 font-medium">
                                <li className="block pt-6 pb-5">
                                    <LinkButton
                                        className="block w-1/2 py-2 ms-3 text-center hover:bg-gray-100 bg-white border border-gray-300 rounded-lg"
                                        href="/reminders/add"
                                    >
                                        New
                                    </LinkButton>
                                </li>

                                <li className="flex pb-4 items-center">
                                    <ul>
                                        <li className="flex pl-2 pb-1 items-center">
                                            {FilterVerticalIcon}
                                            <Label className={"ms-1 block text-base text-gray-900"}>Type</Label>
                                        </li>
                                        <li>

                                            <input id="checked-checkbox"
                                                type="checkbox"
                                                checked={typeFilter.includes("reminder")}
                                                onChange={(e) => setTypeFilterState(e.target.checked, "reminder")}
                                                className="ms-3 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                            />
                                            <Label className={"ms-2 text-m font-medium text-gray-800"}>
                                                Reminder
                                            </Label>
                                        </li>
                                        <li>
                                            <input id="checked-checkbox"
                                                type="checkbox"
                                                checked={typeFilter.includes("note")}
                                                onChange={(e) => setTypeFilterState(e.target.checked, "note")}
                                                className="ms-3 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                            />
                                            <Label className={"ms-2 text-m font-medium text-gray-800"}>
                                                Notes
                                            </Label>
                                        </li>
                                    </ul>
                                </li>

                                <li className="flex items-center pb-1">
                                    <ul>
                                        <li className="flex pl-2 pb-1">

                                            {FilterVerticalIcon}
                                            <Label className={"ms-1 block text-base text-gray-900"}>Expiry Date</Label>
                                        </li>
                                        <li className="pb-1">
                                            <Label className={"ms-3 block text-gray-800"}>From :</Label>
                                            <DateInput
                                                id={"expiry-date"}
                                                value={dateFilter["from"]}
                                                min={"2023-01-01"}
                                                max={"2030-12-31"}
                                                disabled={false}
                                                className="ms-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                                                callBack={setFromDateFilter}
                                            />
                                        </li>
                                        <li>
                                            <Label className={"ms-3 block text-gray-800"}>To :</Label>
                                            <DateInput
                                                id={"expiry-date"}
                                                value={dateFilter["to"]}
                                                min={"2023-01-01"}
                                                max={"2030-12-31"}
                                                disabled={false}
                                                className="ms-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                                                callBack={setToDateFilter}
                                            />
                                        </li>
                                    </ul>
                                </li>

                                <li className="flex">
                                    <ActionButton
                                        disabled={false}
                                        callBack={() => setFilterStatus("apply")}
                                        className="block w-1/3 py-1 ms-3 text-center hover:bg-gray-100 bg-gray-50 border border-gray-400 rounded-lg text-sm"
                                    >
                                        Apply
                                    </ActionButton>
                                    <ActionButton
                                        disabled={false}
                                        callBack={() => setFilterStatus("clear")}
                                        className="block w-1/3 py-1 ms-3 text-center hover:bg-gray-100 bg-gray-50 border border-gray-400 rounded-lg text-sm"
                                    >
                                        Clear
                                    </ActionButton>
                                </li>
                            </ul>
                        }
                        <ul className="fixed bottom-3 w-1/2 md:w-auto">
                            <li>
                                <ActionButton
                                    disabled={false}
                                    callBack={doLogOut}
                                    className="block w-1/2 md:w-auto py-2 px-3 ms-3 text-center hover:bg-gray-100 bg-white border border-gray-300 rounded-lg">
                                    Logout
                                </ActionButton>
                            </li>
                        </ul>
                    </div>
                </div>
            }
            <FilterContext.Provider
                value={
                    contextValue
                }
            >
                {props.children}
            </FilterContext.Provider>
        </div>
    )
}

//export const useFilter = useContext(FilterContext)

export const useFilter = () => {
    return useContext(FilterContext)
}

export default SideBar