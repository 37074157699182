import { useState } from "react"
import { CrossIcon, DeleteIcon } from "../resources/Icons"
import ActionButton from "./ActionButton"

const Modal = ({ ...props }) => {
    const [modal, setModal] = useState(false)
    const setShowModal = (value: boolean) => {
        setModal(value)
    }
    return (
        <div className="flex items-center">
            {
                props.children &&
                <div
                    onClick={() => setShowModal(true)}
                    className="flex items-center">
                    {props.children}
                </div>
            }
            {
                modal &&
                <div>
                    <div className="fixed top-0 left-0 w-full h-full backdrop-brightness-50"></div>
                    <div id="default-modal" tabIndex={-1} aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed inset-x-0 inset-y-1/3 md:inset-x-1/3 md:inset-y-1/3  z-1000  h-[calc(100%-1rem)] max-h-full place-items-center">
                        <div className="relative p-4  max-w-2xl max-h-full">
                            <div className="relative bg-gray-50 rounded-lg border shadow">
                                <div className="flex items-center p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 className="basis-11/12 text-xl font-semibold text-gray-900 text-center">
                                        Are you sure?
                                    </h3>
                                    <ActionButton
                                        disabled={false}
                                        className="pr-4"
                                        callBack={() => setShowModal(false)}>
                                        {CrossIcon}
                                    </ActionButton>

                                </div>

                                <div className="p-4 md:p-5 space-y-4">
                                    <p className="text-center leading-relaxed text-gray-700">
                                        Do you want to delete this record?
                                    </p>
                                </div>

                                <div className="flex items-center justify-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <ActionButton
                                        disabled={false}
                                        className={"btn-primary"}
                                        callBack={() => {
                                            props.callBack()
                                            setShowModal(false)
                                        }}>
                                        Sure
                                    </ActionButton>
                                    <ActionButton
                                        disabled={false}

                                        className={"btn-primary"}
                                        callBack={() => setShowModal(false)}>
                                        Nope
                                    </ActionButton>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default Modal