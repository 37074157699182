import { useEffect, useRef, useState } from "react"
import Input from "../components/Input"
import { deleteReminder, getReminder, getReminders, updateReminder } from "../api/api"
import moment from "moment"
import InputArea from "../components/InputArea"
import DateInput from "../components/DateInput"
import RadioGroup from "../components/RadioGroup"
import Label from "../components/Label"
import ActionButton from "../components/ActionButton"
import { ReminderType } from "../types/types"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { DeleteIcon, EditIcon } from "../resources/Icons"
import Toast from "../components/Toast"
import Modal from "../components/Modal"
import { useMutation, useQuery } from "@tanstack/react-query"
import Loading from "../components/Loading"
import { formatDateWithDelta } from "../util/util"

const ReminderDetailPage = () => {
    let { reminderId } = useParams()
    const navigate = useNavigate()
    let reminder: ReminderType = {} as ReminderType
    console.log('search params', reminderId);

    const fetchReminder = async () => {
        const reminderQueryResponse: ReminderType = await getReminder(reminderId || "")
        if (reminderQueryResponse) {
            reminder = reminderQueryResponse
            setTitle(reminder.title)
            setDescription(reminder.description || "")
            setExpiryDate(reminder.expiryDate || "")
            setType(reminder.type)
            const remind = reminder.remind
            setRemind(remind)
            if(remind){
                const formatted = `${reminder.reminderDate}T${reminder.reminderTime}:00.000Z`
                const formattedDateTime = formatDateWithDelta(new Date(formatted), 0)
                console.log('formattedDateTime: ', formattedDateTime)
                const [_currentDate, _currentTime] = formattedDateTime.split('T')
                setReminderDate(_currentDate || "")
                setReminderTime(_currentTime || "")
            }
        }
        return reminderQueryResponse
    }

    const processUpdateSuccess = () => {
        setSaveState({
            type: "success",
            message: "The record is updated successfully"
        })
        setEditEnabled(false)
    }
    const processUpdateError = () => {
        console.log('error while update from query')
        setSaveState({
            type: "error",
            message: "The record cannot be updated"
        })
    }
    const processDeleteSuccess = () => {
        console.log('deleted successfully from query')
        localStorage.setItem('saveState', "deleted")
        return navigate('/')
    }
    const processDeleteError = () => {
        console.log('error while delete from query')
        setSaveState({
            type: "error",
            message: "The record cannot be updated"
        })
    }

    const reminderQuery = useQuery({
        queryKey: ['getReminder', reminderId],
        queryFn: async () => await fetchReminder()
    })

    const reminderUpdateMutation = useMutation({
        mutationFn: (updateData: ReminderType) => updateReminder(updateData),
        onSuccess: processUpdateSuccess,
        onError: processUpdateError
    })

    const reminderDeleteMutation = useMutation({
        mutationFn: (id: string) => deleteReminder(id),
        onSuccess: processDeleteSuccess,
        onError: processDeleteError
    })

    useEffect(() => {
        if (reminderQuery.isError) {
            console.error('error while fetching reminder. Redirecting to Homepage')
            return navigate('/')
        }
    }, [reminderQuery.isError])

    const [editEnabled, setEditEnabled] = useState(false)
    const [type, setType] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [expiryDate, setExpiryDate] = useState("")
    const [remind, setRemind] = useState(false)
    const [saveState, setSaveState] = useState<{ type: string, message: string }>(
        {
            type: "",
            message: ""
        }
    )
    const formattedDateTime = formatDateWithDelta(new Date(), 30) // get 30 mins ahead of UTC
    const [currentDate, currentTime] = formattedDateTime.split('T')
    const [reminderDate, setReminderDate] = useState(currentDate)
    const [reminderTime, setReminderTime] = useState(currentTime)
    const titleRef = useRef<HTMLInputElement>(null)
    const reminderDateRef = useRef<HTMLInputElement>(null)
    const reminderTimeRef = useRef<HTMLInputElement>(null)

    const doValidate = () => {
        if (!title) {
            setSaveState({
                type: "error",
                message: "Please enter value for Title"
            })
            titleRef.current?.focus()
            return false
        }
        if (remind) {
            if (!reminderDate) {
                setSaveState({
                    type: "error",
                    message: "Please enter value for Reminder Date"
                })
                reminderDateRef.current?.focus()
                return false
            }
            if (!reminderTime) {
                setSaveState({
                    type: "error",
                    message: "Please enter value for Reminder Time"
                })
                reminderTimeRef.current?.focus()
                return false
            }
            const currentDate = new Date()
            currentDate.setMinutes(currentDate.getMinutes() + 30) // get 30 mins ahead of UTC
            const utcCurrentTime = currentDate.toISOString()
            console.log('utcCurrentTime', utcCurrentTime)
            const formattedReminderTime = `${reminderDate}T${reminderTime}:00.000`.replaceAll('/', '-')
            const utcReminderTime = new Date(formattedReminderTime).toISOString()
            console.log('utcReminderTime', utcReminderTime)
            if (utcReminderTime < utcCurrentTime) {
                setSaveState({
                    type: "error",
                    message: "Please enter a time at least 30 mins greater than current time"
                })
                reminderDateRef.current?.focus()
                return false
            }
        }
        return true
    }

    const editReminder = async () => {
        const validated = doValidate()
        if (validated) {
            const formattedReminderTime = `${reminderDate}T${reminderTime}:00.000`.replaceAll('/', '-')
            const utcReminderTime = new Date(formattedReminderTime).toISOString()
            const formattedUtcTime = moment(utcReminderTime).utc().format('YYYY-MM-DDTHH:mm')
            console.log('formattedUtcTime: ', formattedUtcTime)
            const [_reminderDate, _reminderTime] = formattedUtcTime.split('T')
            reminderUpdateMutation.mutate({
                id: reminderId || "",
                title,
                description,
                type,
                expiryDate,
                remind,
                reminderDate: _reminderDate,
                reminderTime: _reminderTime,
                createdAt: reminderQuery?.data?.createdAt || ""
            })
        }
    }

    const removeReminder = async () => {
        reminderDeleteMutation.mutate(reminderId || "")
    }

    const setTitleState = (value: string) => {
        setSaveState({
            type: "",
            message: ""
        })
        setTitle(value)
    }

    const setReminderDateState = (value: string) => {
        setSaveState({
            type: "",
            message: ""
        })
        setReminderDate(value)
    }

    const setReminderTimeState = (value: string) => {
        setSaveState({
            type: "",
            message: ""
        })
        setReminderTime(value)
    }

    const radioDataList = [
        {
            id: "radio-reminder",
            value: "reminder",
            type: type,
            title: "Reminder",
            disabled: true,
            callBack: setType
        },
        {
            id: "radio-note",
            value: "note",
            type: type,
            title: "Note",
            disabled: true,
            callBack: setType
        }
    ]
    const inputClassNormal = "bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500"
    const inputClassError = "bg-red-50 border border-red-500 text-red-900 text-m rounded-lg focus:ring-red-500 focus:border-red-500"

    return (
        <div>
            {
                (reminderQuery.isLoading || reminderUpdateMutation.isPending || reminderDeleteMutation.isPending) &&
                <Loading />
            }
            <div className="flex flex-col items-center">
                <div className="w-full sm:w-96 p-4 md:p-0 md:pt-4">
                    <div className="flex pb-4 justify-center">
                        <Label className={"text-3xl font-semibold text-gray-900"}>{reminder?.title || ""}</Label>
                    </div>
                    {
                        saveState.type === 'error' &&
                        <Toast
                            type={saveState.type}
                            message={saveState.message} />
                    }
                    {
                        saveState.type === 'success' &&
                        <Toast
                            type={saveState.type}
                            message={saveState.message} />
                    }
                    <div className="flex justify-end items-center gap-4">
                        <ActionButton
                            disabled={false}
                            callBack={() => {
                                setEditEnabled(true)
                                setTimeout(() => { titleRef.current?.focus() }, 1)
                            }}
                        >
                            {EditIcon}
                        </ActionButton>
                        <Modal callBack={removeReminder}>
                            <ActionButton
                                disabled={false}
                            >
                                {DeleteIcon}
                            </ActionButton>
                        </Modal>
                    </div>
                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Type</Label>
                        <RadioGroup radioGroupList={radioDataList} />
                    </div>
                    <div className="pb-4">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900 after:content-['*'] after:text-red-500"}>Title</Label>
                        <Input
                            id={"add-reminder-title"}
                            value={title}
                            inputRef={titleRef}
                            disabled={!editEnabled}
                            className={saveState.type === 'error' && !title ? `${inputClassError} w-full p-2.5` : `${inputClassNormal} w-full p-2.5`}
                            callBack={setTitleState} />
                    </div>
                    <div className="pb-2">
                        <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Description</Label>
                        <InputArea
                            id={"input-description"}
                            value={description}
                            disabled={!editEnabled}
                            className={"p-2.5 w-full text-m text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"}
                            callBack={setDescription}
                        />
                    </div>
                    {
                        type === "reminder" &&
                        <div className="pb-3">
                            <div className="pb-4">
                                <Label className={"block pb-1 text-lg font-medium text-gray-900"}>Expiry</Label>
                                <DateInput
                                    id={"expiry-date"}
                                    value={expiryDate}
                                    min={"2023-01-01"}
                                    max={"2030-12-31"}
                                    disabled={!editEnabled}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-m rounded-lg focus:ring-blue-500 focus:border-blue-500 w-2/5 p-2.5"
                                    callBack={setExpiryDate}
                                />
                            </div>
                            <div className="pb-3">
                                <div className="flex items-center">
                                    <input id="checked-checkbox"
                                        type="checkbox"
                                        checked={remind}
                                        disabled={!editEnabled}
                                        onChange={(e) => setRemind(e.target.checked)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                    />
                                    <Label className={"ms-2 text-m font-medium text-gray-900"}>
                                        Do you want us to remind you?
                                    </Label>
                                </div>
                            </div>
                            {
                                remind &&
                                <div>
                                    <div className="pb-2">
                                        <Label className={"block mb-1 text-lg font-medium text-gray-900 after:content-['*'] after:text-red-500"}>When?</Label>
                                        <div className="flex gap-x-2">
                                            <DateInput
                                                id={"reminder-date"}
                                                value={reminderDate}
                                                min={currentDate}
                                                max={"2030-12-31"}
                                                dateInputRef={reminderDateRef}
                                                disabled={!editEnabled}
                                                className={saveState.type === 'error' && !reminderDate ? `${inputClassError} w-2/5 p-2.5` : `${inputClassNormal} w-2/5 p-2.5`}
                                                callBack={setReminderDateState}
                                            />
                                            <input
                                                type="time"
                                                id="time"
                                                min="09:00"
                                                max="24:00"
                                                value={reminderTime}
                                                ref={reminderTimeRef}
                                                disabled={!editEnabled}
                                                className={saveState.type === 'error' && !reminderTime ? `${inputClassError} w-2/5 p-2.5 leading-none` : `${inputClassNormal} w-2/5 p-2.5 leading-none`}
                                                onChange={(e) => setReminderTimeState(e.target?.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <div className="flex w-full justify-center">
                        <ActionButton
                            disabled={!editEnabled}
                            className={"btn-primary"}
                            callBack={editReminder}>
                            Update
                        </ActionButton>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default ReminderDetailPage