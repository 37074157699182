import { ReminderType } from "../types/types"

const Tile = ({ ...props }: ReminderType) => {
    return (
        <div className="w-full md:w-64 h-56 md:h-48 p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 text-center">
            <div className="pb-2">
                <span className="block font-medium">{props.title}</span>
            </div>
            <div>
                <span className="text-sm"> Type: </span>
                <span className="text-sm font-light"> {props.type} </span>
            </div>
            {
                props.expiryDate &&
                <div>
                    <span className="text-sm"> Expires At: </span>
                    <span className="text-sm font-light"> {props.expiryDate} </span>
                </div>
            }
        </div>
    )
}
export default Tile