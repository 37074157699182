import { LineBarIcon } from "../resources/Icons"
import Avatar from "./Avatar"
import myImage from '../resources/ever-remind-logo-black-transparent.png'

const NavBar = ({ ...props }) => {
    return (
        <div className="sticky top-0 left-0 right-0 z-50 w-full border-b border-gray-200">
            <nav className="bg-gray-100 border-gray-200 p-3 md:py-3 px-4">
                <div className="flex flex-wrap md:justify-between">
                    {
                        props.showSideBar &&
                        <button type="button" onClick={() => props.callBack && props.callBack()} className="inline-flex items-center pr-7 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
                            {LineBarIcon}
                        </button>
                    }
                    <a href="/">
                    <div style={{
                        backgroundImage: `url(${myImage})`,
                        backgroundSize: 'cover',
                        height: '50px',
                        width: '272px'
                    }}>
                    </div>
                    </a>
                    {
                        props.showAvatar &&
                        <div className="hidden md:block" id="navbar-buttons">
                            <a href="/account" className="flex items-center">
                                <Avatar />
                            </a>
                        </div>
                    }
                </div>
            </nav>
        </div>
    )
}
export default NavBar